// Eigene Variablen
$section-padding-xs:                50px;
$section-padding-sm:                70px;
$section-padding-md:                80px;
$section-padding-lg:                110px;
$section-padding-xl:                140px;
$section-padding-xxl:               170px;





