.hero-section {
    overflow: hidden;
    .hero-section-content {
        height:100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: $grid-gutter-width * 4;
        padding-top: $grid-gutter-width * 5;
        .hero-section-header {
            width: fit-content;
            margin: 0 auto;
            img {
                margin-left: auto;
                margin-bottom:-$grid-gutter-width * 0.75;
                display: block;
            }
            h1 {
                padding-right: $grid-gutter-width * 2.75;
            }
        }
        h3 {
            font-family: $headings-font-family;
            font-weight: $font-weight-bold;
        }

        .hero-section-footer {
            height: 100%;
            display: flex;
            justify-content: flex-end;
        }
        
        @include media-breakpoint-down(xxl) {
            padding-left: $grid-gutter-width * 2;
            padding-top: $grid-gutter-width * 2;
            .hero-section-header {
                img {
                    margin-right: -75px;
                }
                h1,.h1 {
                    font-size: 4.5rem;
                    padding-right: 0;
                }
            }
            .hero-section-footer {
                img {
                    max-height: 300px;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            .hero-section-header {
                img {
                    margin-right: 0;
                }
            }
        }
    }
    .hero-section-bg-image {
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
    }
}