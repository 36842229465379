.posts-instruction {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $grid-gutter-width * 2;
    @include media-breakpoint-up(xl) {
        padding-left: 5rem;
    }
    
    .card {
        counter-increment: item;
    }

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(1, 700px);
        justify-content: center;
    }
    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 500px);
        justify-content: center;
    }
    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 85vw);
        justify-content: center;
    }
}

.card {
    box-shadow: 0 3px 10px rgba($color: #000000, $alpha: .16);
    height:100%;
    .card-header {
        padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width $grid-gutter-width*2;
        position: relative;
        .disrupt {
            position: absolute;
            right:$grid-gutter-width;
            top:0;
            padding: .5rem 1.15rem 1rem 1rem;
            font-weight: $font-weight-bold;
                color:$primary;
        }
    }
    .card-body {
        padding-top: $grid-gutter-width;
        p {
            margin-bottom: 0;
        }
        h4 {
            font-weight: $font-weight-bold;
        }
    }

    .card-body,.card-footer {
        padding-left:3rem;
        padding-right:$grid-gutter-width;
        padding-bottom: 45px;
    }
    @include media-breakpoint-only(lg) {
        .card-body,.card-footer {
            padding-left:1.5rem;
        }
    }

    @include media-breakpoint-down (sm) {
        .card-header {
            padding: 0 $grid-gutter-width $grid-gutter-width $grid-gutter-width;
            .disrupt {
                position: static;
                width: fit-content;
                margin-left: auto;
                padding: .5rem 1.15rem 1rem 1rem;
                margin-bottom: 1rem;
            }
        }
        .card-body,.card-footer {
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
            padding-bottom: $grid-gutter-width * 1.5;
        }
    }

    &.card-sm {
        background-color: $primary;
        text-align: center;
        position: relative;
        &::before {
            
            content:counter(item);
            display: flex;
            justify-content: center;
            align-items: center;
            background: $secondary;
            color:$primary;
            position: absolute;
            left:-28px;
            top:19px;
            border-radius: 50%;
            height:58px;
            width: 58px;
            text-align: center;
            font-size: $h4-font-size;
            font-weight: $font-weight-bold;
           
        }
        img {
            margin:2.375rem 0;
        }
        .card-body {
            text-align: start;
            background-color: $white;
            padding: 1rem;
        }
        @include media-breakpoint-down(lg) {
            .card-body {
                text-align: center;
            }
        }
    }
}