.live-donate {

    h3,h4,h5 {
        font-weight: $font-weight-bold;
        text-align: center;
    }
    h3,h5 {
        text-transform: uppercase;
        color:$primary;
    }
    .donate-progress-bar {
        position: relative;
        border-radius: 34px;
        height:70px;
        border: 6px solid $primary;
        display: flex;
        align-items: center;
        margin-top: $grid-gutter-width * 2.333;
        margin-bottom: $grid-gutter-width;

        .donate-progress {
            background: $secondary;
            height:48px;
            border-radius: 34px;
            margin: 0 6px;
            width:0;
            transition: width .5s ease-in-out;
        }
    }
    .progressbar-icon {
        position: absolute;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        top:50%;
        transform: translateY(-50%);
        right:-100px;
    }

    @include media-breakpoint-down(sm) {
        .donate-progress-bar {
            margin-top: $grid-gutter-width * 7;
        }
        .progressbar-icon {
            top:-125px;
            left:50%;
            transform: translateX(-50%);
        }
    }
}