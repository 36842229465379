.posts-grid {

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $grid-gutter-width * 3.266;
    grid-row-gap:$grid-gutter-width * 4;
    margin-bottom: $grid-gutter-width * 3;

    @include media-breakpoint-down(xl) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: $grid-gutter-width * 1.6;
        grid-row-gap:$grid-gutter-width * 2;
        margin-bottom: $grid-gutter-width * 1.5;
    }
    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.post-card {
   
    .post-header {
        display: flex;
        align-items: center;
        padding:9px 23px 9px 20px;
        background:$white;
        .profil-image {
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            max-height: 50px;
            background-size: cover;
            padding-top: 50px;
            margin-right: 12px;
        }
    }
    p {
        color:$black;
        margin-bottom: 0;
    }
    i {
        font-size: $h4-font-size;
       margin-left: auto;
    }
    img {
        object-fit: cover;
        width: 100%;
        object-position: center;
    }
}

.wrapper {
    display: grid;
    grid-template-columns: 100px 100px 100px;
    grid-gap: 10px;
    background-color: #fff;
    color: #444;
  }