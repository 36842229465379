.form {
    margin-top: $grid-gutter-width * 1.33;
    .form-group {
        position: relative;
        margin-bottom: $grid-gutter-width;
        .form-control {
            height:70px;
            width:100%;
            border: 2px solid $primary;
            padding-left: 1rem;
            &:focus,
            &.focus,
            &.has-content {
                box-shadow: none;
                + label {
                    top:-15px;
                    padding: .15rem;
                    font-size: .95rem;
                }
            }
        }
        label {
            position: absolute;
            top: 50%; 
            left: 50%;
            text-align: left;
            font-family:$font-family-sans-serif;
            font-size: $h5-font-size;
            font-weight: $font-weight-normal;
            text-transform: uppercase;
            background: transparent;
            pointer-events: none;
            color:$primary;
            transform: translate(-50%,-50%);
            transition: all .15s ease-in-out;

            @include media-breakpoint-down (md) {
                width:100%;
                text-align: center;
            }
            @include media-breakpoint-down (sm) {
                font-size: $font-size-base;
            }
        }
    }
    .form-submit-btn {
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-down(sm) {
            justify-content: center;
        }
    }
}

.postcard-slider {
    width: 867px;
    max-width: calc(100% - 3rem);
    margin: 0 auto;

    // #file-ip-1-preview {
    //     object-fit: cover;
    //     object-position: center;
    // }
    .postcard {
        // border: 20px solid $yellow;
        position: relative;
        box-shadow: 0 5px 10px rgba($black,.16);
        &.backside {
            border: none;

            @include media-breakpoint-down(md) {
                .card-back-logo {
                    img {
                        max-height: 50px;
                    }
                }
                .form-control {
                    height:20px;
                    font-size: .75rem;
                }
                .card-back-textbox {
                    .form-control {
                        height:100%;
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                .form-control {
                    font-size: .5rem;
                }
            }

        }
        .card-back {
            &::before {
                content:"";
                padding-top: calc(100% * 105 / 148);
                display: block;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .postcard-slider-navigation {
            .slider-btn {
                display: flex;
                flex-direction: column;

                #postcard-preview {
                    margin-left: 0;
                    margin-top: $grid-gutter-width;
                }
            }
        }
    }
}

#card-name:focus-visible {
    outline: none;
}
.form-input {
    input {
        display:none;
    }
}
   
  .form-input label {
    padding-left: 30px;
    position: relative;
    text-align:center;
    text-transform:Uppercase;
    color:$primary;
    cursor:pointer;

    &::before {
        content:"";
        background-image: url('./images/icons/upload-icon.svg');
        display: block;
        width:25px;
        height:29px;
        position: absolute;
        left:0;
        top:0;
    }
}

.form-control {
    width:100%;
    height:39px;
    border: none;
    border-bottom: 1px solid $body-color;

    &[readonly] {
        color: $body-color!important;
        opacity: 1!important;
        &::placeholder {
            color: $body-color!important;
            opacity: 1;
        }
        &:focus {
            outline: none;
        }
    }
}

$card-border: 7;
$card-width: 148;
$card-height: 105;
$card-width-percent: calc(100% / 148);
$card-height-percent: calc(100% / 105);
$card-field-height: $card-height-percent * 7;
$card-field-width: $card-height-percent * 37;
$card-field-spacer: $card-field-width * .05;


.card-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-back {
    color: $primary;
    background: $white;

    >* {
        position: absolute;
    }
}

.card-field {
    width: $card-field-width;
    height: $card-field-height;

    input+.form-error-list {
        display: none;
    }
}

.card-field-input {
    width: 100%;
    height: 100%;
    padding: 0;
    // @include fluid-type(48px, $container-width, 1px, 18px);
    // border-bottom: 1px solid $primary;

    &.parsley-error {
        border-color: $red;
    }

    &[readonly] {
        color: $body-color;
        background: transparent !important;

        &::placeholder {
            color: $body-color;
        }
    }
}


.card-back-logo {
    left: $card-width-percent * $card-border;
    top: $card-height-percent * $card-border;
}

.card-back-stamp {
    background: rgba($primary, .1);
    right: $card-width-percent * $card-border;
    top: $card-height-percent * $card-border;
    width: $card-width-percent * 16;
    height: $card-height-percent * 21;
    background-color: $light;
}

.card-back-footer {
    left: $card-width-percent * $card-border;
    bottom: $card-height-percent * $card-border;
    width: $card-width-percent * 75;
}

.card-back-textbox {
    left: $card-width-percent * $card-border;
    bottom: $card-height-percent * $card-border + $card-height-percent * 18;
    width: $card-width-percent * 75;
    height: $card-height-percent * 57;
    
    .card-field-input {
        padding: 3%;
        line-height: 1.5;
        background: rgba($primary, .1);
        border: none !important;
        resize: none;
        height: 100%;
        background-color: $light;
        &[readonly] {
            padding: 0;
        }
    }

    @include media-breakpoint-down(md) {
        height: $card-height-percent * 45;
        .card-field-input {
            height: 100%;
        }
    }
}

.card-back-company {
    right: $card-width-percent * $card-border;
    bottom: $card-height-percent * $card-border + $card-height-percent * 18 + ($card-field-height + $card-field-spacer) * 4;
    width: $card-field-width; 
}

.card-back-name {
    right: $card-width-percent * $card-border;
    bottom: $card-height-percent * $card-border + $card-height-percent * 18 + ($card-field-height + $card-field-spacer) * 3;
    width: $card-field-width;
}

.card-back-address {
    right: $card-width-percent * $card-border + $card-field-width * .3 + $card-field-spacer;
    bottom: $card-height-percent * $card-border + $card-height-percent * 18 + ($card-field-height + $card-field-spacer) * 2;
    width: $card-field-width * .7 - $card-field-spacer;
}

.card-back-house {
    right: $card-width-percent * $card-border;
    bottom: $card-height-percent * $card-border + $card-height-percent * 18 + ($card-field-height + $card-field-spacer) * 2;
    width: $card-field-width * .3;
}

.card-back-postal {
    right: $card-width-percent * $card-border + $card-field-width * .7 + $card-field-spacer;
    bottom: $card-height-percent * $card-border + $card-height-percent * 18 + $card-field-height + $card-field-spacer;
    width: $card-field-width * .3 - $card-field-spacer;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
}

.card-back-city {
    right: $card-width-percent * $card-border;
    bottom: $card-height-percent * $card-border + $card-height-percent * 18 + $card-field-height + $card-field-spacer;
    width: $card-field-width * .7;
}

.card-back-country {
    right: $card-width-percent * $card-border;
    bottom: $card-height-percent * $card-border + $card-height-percent * 18;
    width: $card-field-width;
}

.error-message {
    right: $card-width-percent * $card-border;
    bottom: $card-height-percent * $card-border + $card-height-percent * 10;
    width: $card-field-width; 
    @include media-breakpoint-down (md){
        width:100%;
    }
}
.card-back-edit{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    svg {
        margin-left: 30%;
    }
    
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.offcanvas-bottom {
	height: 100vh;
    visibility: unset!important;
    #step-3-overlay {

        .form-group {
            margin-bottom: $grid-gutter-width;
            .form-control:not(textarea) {
                height:50px;
                border: none;
                border-bottom: 2px solid $body-color;
            }
            .form-control:is(textarea) {
                height:100%;
            }
        }
    }
    .btn-outline-primary {
        color:$primary!important;

        &:hover,&:focus,&:active {
            color:$white!important;
        }
    }
}

.form-control.error {
    border-color: $red;
    &::-webkit-input-placeholder {
        color:$red;
    }
    
    &::-moz-placeholder {
        color:$red;
    }
    
    ::-ms-placeholder {
        color:$red;
    }
    
    ::placeholder {
        color:$red;
    }
}
::-webkit-input-placeholder {
    color:$body-color;
    opacity: 1;
}

::-moz-placeholder {
    color:$body-color;
    opacity: 1;
}

::-ms-placeholder {
    color:$body-color;
    opacity: 1;
}

::placeholder {
    color:$body-color;
    opacity: 1;
}