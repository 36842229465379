.postcard-config {
    position: relative;
    
    .background-image-container {
        display: flex;
        position: relative;
        .bg-image-left,.bg-image-right {
            width:50vw;
            img {
                width: 100%;
            }
        }

        @include media-breakpoint-down(xl) {
            flex-direction: column;
        }
        @include media-breakpoint-down (lg) {
            // display: block;
            .bg-image-left,.bg-image-right {
                width:50vw;
                img {
                    aspect-ratio: 4 / 3;
                    object-fit:cover;
                    object-position: center;
                    height: 100%;
                }
            }
        }
    }
    
    .postcard-config-container {
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        width:100%;
        // display: flex;
        // align-items: center;

        @include media-breakpoint-down(xl) {
            position: static;
            transform: translateY(0%);
            width:100vw;
        }
        @include media-breakpoint-down(lg) {
            &>.container {
                max-width: 100%;
            }
        }
    }
    .postcard-config-body {
        position: relative;
        background: $white;
        padding: $grid-gutter-width * 2.5;

        &::before {
            content:"";
            background-image:  enthusiasm-share-icon($primary);
            background-repeat: no-repeat;
            display: block;
            width:147px;
            height:147px;
            position: absolute;
            right:0;
            top:0;
        }

        .postcard-config-headline {
            width:85%;
            margin-bottom: $grid-gutter-width;
        }
        @include media-breakpoint-down(md) {
            padding: $grid-gutter-width * 1.5;
            padding-left:1rem;
        }
        @include media-breakpoint-down(sm) {
            .postcard-config-headline {
                width:100%;
            }
            padding: $grid-gutter-width *0.5;
            &::before {
                content:"";
                background-image:  enthusiasm-share-icon($primary);
                display: block;
                position: static;
                margin-left: auto;
                width:100px;
                height:100px;
                background-size: 90%;
               
            }
        }
    }
    .postcard-slider {
        padding-top: $grid-gutter-width * 6;
    }
    .postcard-front-slider {
        padding-top: $grid-gutter-width * 6;
        .postcard-slider {
            padding-top: 0;
            overflow: hidden;
            img {
                max-height:575px;
                object-fit: contain;
                width: 100%;
            }
        }
        
        .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
            bottom: -30px;
        }
        @include media-breakpoint-down(md) {
            padding-top: $grid-gutter-width * 4;
        }
        @include media-breakpoint-down(sm) {
            padding-top: $grid-gutter-width * 2;
        }
    }

    .postcard-slider-navigation {

        // max-width: calc(100% - 3rem);
        max-width: 867px;
        padding-top: $grid-gutter-width * 2;
        padding-bottom: $grid-gutter-width * 5;
        display: flex;
        align-items: center;
        background: $white;
        .slider-btn {
            margin-left:auto;

            .btn-primary {
                margin-left:1rem;
            }
        }
        @include media-breakpoint-down(lg) {
            padding-bottom: 4.5rem;
        }
        @include media-breakpoint-down(md) {
           flex-direction: column;
            padding-bottom: $grid-gutter-width * 2;
            .slider-btn {
                margin-left:0;
                margin-top: $grid-gutter-width;
            }
        }

        @include media-breakpoint-down(sm) {
            .slider-btn {
                display: flex;
                flex-direction: column;

                .btn-outline-primary {
                    margin-bottom: $grid-gutter-width;
                }
            }
        }
    }

    @include media-breakpoint-down (lg) {
        .postcard-slider-navigation {
            margin-top: $grid-gutter-width *2;
            display: flex;
            justify-content: center;

            .btn-primary {
                margin-left: $grid-gutter-width * 0.66;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .postcard-slider-navigation {
            margin-top:0;
        }
    }
}

.slide {
    position: relative;
}


.card-wrapper {

    .form-control {
        pointer-events: none;
    }
}

.card-flipper {
    perspective: 1000px;
    position: relative;
}

.cards {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;

    .form-control {
        pointer-events: none;
    }
}

.card-preview {
    position: absolute;
    width: 100%;
    height: 100%;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.card-preview-front {
    display: flex;
    // border: 20px solid $yellow;
    img {
        width:100%;
    }
}
.card-preview-back {
   
    transform: rotateY(180deg);
}

.cards.is-flipped {
    transform: rotateY(180deg);
}
.flip-btn {
    position: absolute;
    left:50%;
    top:-60px;
    cursor: pointer;
    background: $body-color;
    border-radius: 34px;
    padding: .5rem 1rem;
    color:$white;
    transform: translateX(-50%);
    margin-right: .5rem;
    svg {
        fill:$white;
        width:15px;
    }
}

.swiper-button-prev {
    left: calc(100vw - ((100vw + 867px)/ 2) - 4rem)
}

.swiper-button-next {
    left:calc(100vw - ((100vw - 867px)/ 2) + 2rem);
}

.swiper-button-next,.swiper-button-prev {
 
    @include media-breakpoint-down(lg) {
        display: none;
    }
}
.swiper-container,.swiper-slide {
    height:auto;
}

.swiper-pagination {
    display: none;
    // .swiper-pagination-bullet {
    //     width: 20px;
    //     height:20px;
    // }
    @include media-breakpoint-down(lg) {
        display: block;
    }
}