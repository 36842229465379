section {
	padding-bottom: $section-padding-xs;
	padding-top: $section-padding-xs;
	@include media-breakpoint-up(sm) {
	  padding-bottom: $section-padding-sm;
	  padding-top: $section-padding-sm;
	}
	
	@include media-breakpoint-up(md) {
	  padding-bottom: $section-padding-md;
	  padding-top: $section-padding-md;
	
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: $section-padding-lg;
		padding-top: $section-padding-lg;
	}

	@include media-breakpoint-up(xl) {
		padding-bottom: $section-padding-xl;
		padding-top: $section-padding-xl;
	}
}