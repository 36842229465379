h1,.h1 {
	font-family: $headings-font-family;
}
h1,.h1,h2,.h2 {
	text-transform: uppercase;
	font-weight: $font-weight-bold;
}
h2,.h2 {
	color:$primary;
}
h2,.h2,h3,.h3,.h4,h4,h5,.h5 {
	font-family: $font-family-sans-serif;
}
a {
	text-decoration: none;
}
.btn-primary {
	color:$white;
	font-size: $h5-font-size;
}

.btn-outline-primary,.btn-primary {
	&:hover,&:focus,&:active {
		color:$white;
	}
}

.btn-outline-primary {
	padding: .975rem 1.5rem 1.125rem;
	font-size: $h5-font-size;
	&:focus {
		background: $primary;
	}
	i {
		margin-left: .75rem;
	}
}

.one-by-one {
	aspect-ratio: 1 / 1;

	img {
		object-fit: cover;
		height: 100%;
	}
}

.grid-space-bt {
	margin-bottom: $grid-gutter-width;
}
.brand-fixed {
	font-weight: $font-weight-bold;
	background: $white;
	position: fixed;
	top:80vh;
	right:0;
	padding:.8rem $grid-gutter-width * 2.75 1rem 1.2rem;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	box-shadow: 0 10px 20px rgba($black,.16);
	z-index: 1050;

	@include media-breakpoint-down (md) {
		// top:75vh;
		padding:.8rem 1.2rem 1rem 1.2rem;
	}
}

.posts-show-more-btn {
	
	i {
		font-size: $h5-font-size;
		vertical-align: middle;
	}
}
#js-top {
	font-weight: $font-weight-bold;
	cursor: pointer;
}
.scroll-top-link {
	margin-top: $grid-gutter-width * 1.5;
	margin-bottom: 1.375rem;
	border-bottom: 1px solid rgba($body-color,.4);
}


#canvas-area {
	position: relative !important;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

#canvas {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	pointer-events: none;
	width: 1819px;
	max-width: 1819px;
	height: 1311px;
	max-height: 1311px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $primary;
}
#canvas img {

	object-fit: contain;
	height: 100%;
	width: auto;
}
	
.pb-section {
	padding-bottom: $section-padding-xs;
	@include media-breakpoint-up(sm) { 
		padding-bottom: $section-padding-sm;
	}
	@include media-breakpoint-up(md) { 
		padding-bottom: $section-padding-md;
	}
	@include media-breakpoint-up(lg) { 
		padding-bottom: $section-padding-lg;
	}
    @include media-breakpoint-up(xl) { 
		padding-bottom: $section-padding-xl;
	}
	@include media-breakpoint-up(xxl) { 
		padding-bottom: $section-padding-xxl;
	}
	&-half {
		padding-bottom: ($section-padding-xs *0.5);
		@include media-breakpoint-up(sm) { 
			padding-bottom: ($section-padding-sm*0.5);
		}
		@include media-breakpoint-up(md) { 
			padding-bottom: ($section-padding-md*0.5);
		}
		@include media-breakpoint-up(lg) { 
			padding-bottom: ($section-padding-lg*0.5);
		}
        @include media-breakpoint-up(xl) { 
			padding-bottom: ($section-padding-xl*0.5);
		}
		@include media-breakpoint-up(xxl) { 
			padding-bottom: ($section-padding-xxl * 0.5);
		}
	}
}
.pt-section {
	padding-top: $section-padding-xs;
	@include media-breakpoint-up(sm) { 
		padding-top: $section-padding-sm;
	}
	@include media-breakpoint-up(md) { 
		padding-top: $section-padding-md;
	}
	@include media-breakpoint-up(lg) { 
		padding-top: $section-padding-lg;
	}
    @include media-breakpoint-up(xl) { 
		padding-top: $section-padding-xl;
	}
	@include media-breakpoint-up(xxl) { 
		padding-top: $section-padding-xxl;
	}
	&-half {
		padding-top: ($section-padding-xs*0.5);
		@include media-breakpoint-up(sm) { 
			padding-top: ($section-padding-sm*0.5);
		}
		@include media-breakpoint-up(md) { 
			padding-top: ($section-padding-md*0.5);
		}
		@include media-breakpoint-up(lg) { 
			padding-top: ($section-padding-lg*0.5);
		}
        @include media-breakpoint-up(xl) { 
			padding-top: ($section-padding-xl*0.5);
		}
		@include media-breakpoint-up(xxl) { 
			padding-top: ($section-padding-xxl * 0.5);
		}
	}
}
