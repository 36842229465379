footer{
    background:$light;

    .nav {
        justify-content: center;
        .nav-link {
            color:$body-color;
            transition: color .35s ease-in-out;
            &:hover,&:focus,&:active {
                color:$primary;
            }
        }
    }
}