
// Colors in Sections
.bg- {
    &white { &, [class*="bg-"] &, [class*="bg-"] [class*="bg-"] & {
    
        h1,h1,h2,.h2 {
            color:$primary;
        }

        

    }}


    ////////Primary-Section ////////////
  
    &primary { &, [class*="bg-"] &, [class*="bg-"] [class*="bg-"] & {
        
        h1,h1 {
            color:$white;
        }

        

        
    }}

    ////////Light-Section ////////////
  
    &light { &, [class*="bg-"] &, [class*="bg-"] [class*="bg-"] & {
        
        h1,h1 {
            color:$primary;
        }
    
        
    }}

    //////// Secondray-Section ////////////


    &secondary { &, [class*="bg-"] &, [class*="bg-"] [class*="bg-"] & {
        
        h1,h1 {
            color:$primary;
        }

    }}


   

}

