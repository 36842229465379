/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-weight: $font-weight-normal;
    src: url('./fonts/roboto/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/roboto/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('./fonts/roboto/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/roboto/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-weight: $font-weight-bold;
    src: url('./fonts/roboto/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/roboto/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/roboto/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/roboto/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
        url('./fonts/roboto/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/roboto/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  

/* quicksand-500 - latin */
@font-face {
    font-family: 'Quicksand';
    font-weight: $font-weight-normal;
    src: url('./fonts/quicksand/quicksand-v29-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/quicksand/quicksand-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/quicksand/quicksand-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/quicksand/quicksand-v29-latin-500.woff') format('woff'), /* Modern Browsers */
        url('./fonts/quicksand/quicksand-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/quicksand/quicksand-v29-latin-500.svg#Quicksand') format('svg'); /* Legacy iOS */
}

/* quicksand-700 - latin */
@font-face {
    font-family: 'Quicksand';
    font-weight: $font-weight-bold;
    src: url('./fonts/quicksand/quicksand-v29-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./fonts/quicksand/quicksand-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./fonts/quicksand/quicksand-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('./fonts/quicksand/quicksand-v29-latin-700.woff') format('woff'), /* Modern Browsers */
        url('./fonts/quicksand/quicksand-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./fonts/quicksand/quicksand-v29-latin-700.svg#Quicksand') format('svg'); /* Legacy iOS */
  }
  